<template>
  <div class="thirdBlank">
    <!-- <van-button type="danger">危险按钮</van-button> -->
    <div>{{ data }}</div>
    <div>{{ search }}</div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "thirdBlank",
  data() {
    return {
      data: "",
      search: "",
    };
  },
  created() {
    this.search = window.location.search;
    const query = this.getSearchData(window.location.search);
    this.data = query;
    if (Object.keys(query).length > 0) {
      this.toRouter(query);
      return;
    }
  },
  methods: {
    toRouter(query) {
      /*  appId 决定跳转的路由
          新增则补充
          '1' - 跳转广播站
      */
      if (query.appId === "1") {
        this.$router.push({
          name: "workOrder",
          query,
        });
      }
    },
    // location.search 转换为 对象
    getSearchData(search) {
      let obj = {};
      let arr = search.slice(1).split("&");
      arr.forEach((item) => {
        let newArr = item.split("=");
        obj[newArr[0]] = newArr[1];
      });
      return obj;
    },
  },
};
</script>
